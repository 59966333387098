<template>
  <v-container>
    <v-card elevation="10">
      <v-row justify="center">
        <v-toolbar-title>
          <h4 class="ma-4 teal--text">
            {{ titulo }}
          </h4>
        </v-toolbar-title>
      </v-row>

      <v-row justify="center">
        <v-col cols="7">
          <v-text-field
            v-model="nomtipoact"
            prepend-icon="mdi-pencil"
            label="Nueva actividad"
          />
        </v-col>
        <v-col cols="4">
          <v-switch
            v-model="activo"
            flat
            color="teal"
            label="Estatus"
          />
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="8" />
        <v-col>
          <v-btn
            align="right"
            color="success"
            dark
            small
            class="ma-1"
            @click="agregar"
          >
            <v-icon>mdi-check-circle</v-icon>Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default{
  data(){
    return{
      switch1: false,
			  snackbar: false,
		    nomtipoact:'',
		    idtipoact:'',
      activo: false,
      titulo: '',
		    nModo: 1
    }
  },
	
  computed:{
	    validaInfo(){
	      return  this.nomtipoact   !== ''
	    },
	  },

  created(){
    console.log('router', this.$route.params.tipoact )

    let ruta = this.$route.params.tipoact

	    //MODO EDITAR
	    if ( ruta !== undefined ){
	        this.nModo=2
	        this.readonly= true

	        this.CargarInfo(this.$route.params.tipoact)
	        this.modo = 'Editar'
      this.titulo = 'Editar tipo de actividad'

	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
      this.titulo = "Agrega un nuevo tipo de actividad"
	    }

  },

  methods:{
    ...mapActions("snackbar", ["showSnack"]),


    CargarInfo(tipoact){
      console.log("tipoact", tipoact)
      this.nomtipoact = tipoact.nomtipoact
      this.activo     = tipoact.activo
      this.idtipoact  = tipoact.idtipoact
    },

    agregar(){
				
      //VALIDAR INFORMACION
		    if(!this.validaInfo){
		    	 this.showSnack({
          text: "Debes de escribir un nombre valido para el tipo de actividad",
          color: "orange",
          timeout: 3000,
        });

		    	console.log("FALLA EN LLENAR")
		        // this.snackbar = true ; this.color="orange"
		        // this.text = "Faltan llenar Datos. Nombre, email, telefono"
		      return
		    }

      //Convertir buleano a numero
      let activo2 = +this.activo
      //Converir numero a string
      let activo3 = activo2.toString()
      var payload = {
        nomtipoact: this.nomtipoact,
        activo: activo3
      }
      console.log("payload", payload)

	     //MODO NUEVO
      if (this.nModo == 1) {
        //Dedinir db name
        this.$http.post('v2/insert.tipoact', payload).then(response =>{
			        this.showSnack({
		            text: "Tipo de actividad agregada correctamente",
		            color: "success",
		            timeout: 2000,
		          });

			        this.limpiartipoact()
	            // setTimeout (() => this.$router.push({name:'catclientes'}),2000);
        }).catch(error=>{
            	// this.snackbar = true ;
	            // this.color="error"
	            // this.text = "Problemas para grabar el cliente"
	            console.log(error)
         			this.showSnack({
		            text: "Problema para crear tipo de actividad."+ error,
		            color: "error",
		            timeout: 2000,
		          });
     
        })
        
      } else {
          
        // EDICION DE UNA tipoact.
        this.$http.put('v2/update.tipoact/' + this.idtipoact, payload).then(response =>{
          this.showSnack({
		            text: "Tipo de actividad actualizada correctamente.",
		            color: "green",
		            timeout: 2000,
		          });
          setTimeout (() => this.$router.push({name:'configuracion'}),2000);

        }).catch(error=>{
          console.log(error)
            	this.showSnack({
		            text: "Problema para actualizar tipo de actividad"+ error,
		            color: "error",
		            timeout: 2000,
		          });
        })

      }
    },
    limpiartipoact(){
      this.nomtipoact = ''
    },
   				
   	  regresar(){
      console.log("ruta", this.$route.name)
    },
  }
}
</script>
